
import { defineComponent } from 'vue'
import DownArrow from '../Icons/DownArrow.vue'
import NigerianFlagIcon from '../Icons/NigerianFlagIcon.vue'

export default defineComponent({
  components: { NigerianFlagIcon, DownArrow },
  setup() {
    const pageScroll = () => {
      window.scrollTo(0, 1)
      const pageBottom = document.querySelector('#free_books') as any
      pageBottom.scrollIntoView()
    }

    return { pageScroll }
  }
})
