
import { defineComponent } from 'vue'
import useSimpleCalculator from '@/composables/simple_calculator'

export default defineComponent({
  setup() {
    const {
      monthly_pay,
      pension,
      submitForm,
      clearForm,
      pension_value,
      consolidated_relief_allowance,
      total_relief,
      taxable_pay,
      paye,
      net_pay,
      employer_pension,
      employee_cost_to_company
    } = useSimpleCalculator()
    return {
      monthly_pay,
      pension,
      submitForm,
      clearForm,
      pension_value,
      consolidated_relief_allowance,
      total_relief,
      taxable_pay,
      paye,
      net_pay,
      employer_pension,
      employee_cost_to_company
    }
  }
})
