
import CalculatorTypes from '@/components/Home/CalculatorTypes.vue'
import FirstPart from '@/components/Home/FirstPart.vue'
import SecondPart from '@/components/Home/SecondPart.vue'
import { defineComponent } from 'vue'
import { IonContent, IonPage } from '@ionic/vue'
import ToolsPage from '@/components/Home/ToolsPage.vue'
import AboutPayrollArticles from '@/components/Home/AboutPayrollArticles.vue'
import FooterPart from '@/components/Home/FooterPart.vue'

export default defineComponent({
  components: {
    FirstPart,
    SecondPart,
    CalculatorTypes,
    IonContent,
    IonPage,
    ToolsPage,
    AboutPayrollArticles,
    FooterPart
  },
  setup() {
    //
  }
})
