
import { defineComponent } from 'vue'
import useComplexCalculator from '@/composables/complex_calculator'

export default defineComponent({
  setup() {
    const {
      monthly_pay,
      deduct_pension,
      deduct_nhf,
      basic,
      housing,
      transport,
      others,
      total,
      cra,
      pensions,
      nhf,
      total_relief,
      taxable_pay,
      payee,
      net_pay,
      employer_pensions,
      employee_cost,
      preventDefault,
      submit,
      clear
    } = useComplexCalculator()

    return {
      monthly_pay,
      deduct_pension,
      deduct_nhf,
      basic,
      housing,
      transport,
      others,
      total,
      cra,
      pensions,
      nhf,
      total_relief,
      taxable_pay,
      payee,
      net_pay,
      employer_pensions,
      employee_cost,
      preventDefault,
      submit,
      clear
    }
  }
})
