
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const year = () => {
      return new Date().getFullYear()
    }

    return { year }
  }
})
