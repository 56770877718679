
import { defineComponent, ref } from 'vue'
import ComplexCalculator from './ComplexCalculator.vue'
import SimpleCalculator from './SimpleCalculator.vue'

export default defineComponent({
  components: { SimpleCalculator, ComplexCalculator },
  setup() {
    const calculator = ref(0)
    const switchCalculator = (val: number) => {
      calculator.value = val
    }

    return { calculator, switchCalculator }
  }
})
