
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    image: String,
    title: String,
    link_text: String,
    position: Number
  },
  setup() {
    //
  }
})
