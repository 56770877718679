import Swal from "sweetalert2"
import { computed, ref, watch } from "vue"
import usePaye from '@/composables/paye'

export default () => {
    const monthly_pay = ref('') as any
    const deduct_pension = ref('')
    const deduct_nhf = ref('')
    const basic = ref('') as any
    const housing = ref('') as any
    const transport = ref('') as any
    const others = ref('') as any
    const total = computed(() => {
        const all = basic.value + housing.value + transport.value + others.value
        if (all > 100) {
            basic.value = ''
            housing.value = ''
            transport.value = ''
            others.value = ''
            Swal.fire({
                title: 'Error!',
                text: 'The combination of `Basic`, `Housing`, `Transport`, and `Other Allowances` must be equal to `100`',
                icon: 'error',
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true
            })
            return 0
        }
        return all
    })
    const cra = ref(0) as any
    const pensions = ref(0) as any
    const nhf = ref(0) as any
    const total_relief = ref(0) as any
    const taxable_pay = ref(0) as any
    const payee = ref(0) as any
    const net_pay = ref(0) as any
    const employer_pensions = ref(0) as any
    const employee_cost = ref(0) as any

    const preventDefault = () => {
        const simple_form = document.getElementById('simple_form') as any
        simple_form.addEventListener('submit', function (event: any) {
            event.preventDefault()
        })
    }
    const calcPensions: any = () => {
        const a = 0.08 * ((basic.value / 100) + (housing.value / 100) + (transport.value / 100)) * monthly_pay.value
        if (deduct_pension.value == 'no') {
            pensions.value = 0
            return 0
        } else if (deduct_pension.value == 'yes') {
            pensions.value = a
            return a
        } else {
            return false
        }
    }
    const calcNhf: any = () => {
        const a = 0.025 * (basic.value / 100) * monthly_pay.value
        if (deduct_nhf.value == 'no') {
            nhf.value = 0
            return 0
        } else if (deduct_nhf.value == 'yes') {
            nhf.value = a
            return a
        } else {
            return false
        }
    }
    const calcCra = () => {
        if (calcPensions() != false && calcNhf() != false) {
            let a = 0.01 * (monthly_pay.value - calcPensions() - calcNhf()) * 12
            const b = 0.2 * (monthly_pay.value - calcPensions() - calcNhf()) * 12
            if (a <= 200000) {
                a = 200000
            }
            cra.value = ((a + b) / 12)
            return (a + b) / 12
        } else {
            return 0
        }
    }
    const calcTotalRelief = () => {
        const a = calcCra() + calcPensions() + calcNhf()
        total_relief.value = a
        return a
    }
    const calcTaxablePay = () => {
        if (calcTotalRelief() > monthly_pay.value) {
            const a = monthly_pay.value
            taxable_pay.value = a
            return monthly_pay.value
        } else {
            taxable_pay.value = (monthly_pay.value - calcTotalRelief())
            return monthly_pay.value - calcTotalRelief()
        }
    }
    const calcNetPay = () => {
        net_pay.value = (monthly_pay.value - pensions.value - nhf.value - payee.value)
        return monthly_pay.value - pensions.value - nhf.value - payee.value
    }
    const calcEmployerPensions = () => {
        employer_pensions.value = ((calcPensions() / 8) * 10)
        return (calcPensions() / 8) * 10
    }
    const calcEmployeeCost = () => {
        employee_cost.value = (monthly_pay.value + calcEmployerPensions())
        return monthly_pay.value + calcEmployerPensions()
    }
    const validator = () => {
        if (monthly_pay.value != '') {
            if (basic.value + housing.value + transport.value + others.value == 100) {
                if (deduct_pension.value != '') {
                    if (deduct_nhf.value != '') {
                        return true
                    } else {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Select an option in `Deduct NHF`',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 6000,
                            timerProgressBar: true
                        })
                        return false
                    }
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Select an option in `Deduct Pension`',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 6000,
                        timerProgressBar: true
                    })
                    return false
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'The combination of `Basic`, `Housing`, `Transport`, and `Other Allowances` must be equal to `100`',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 8000,
                    timerProgressBar: true
                })
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Fill in the monthly pay.',
                icon: 'error',
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true
            })
        }
    }
    const calcPaye = () => {
        if (validator()) {
            const use_paye = usePaye(calcTaxablePay(), calcTotalRelief(), monthly_pay.value).calcPaye()
            payee.value = use_paye
            return use_paye
        } else {
            return ''
        }
    }
    const submit = () => {
        if (validator()) {
            preventDefault()
            calcPaye()
            calcNetPay()
            calcEmployeeCost()
        }
    }
    const clear = () => {
        preventDefault()
        monthly_pay.value = ''
        deduct_pension.value = ''
        deduct_nhf.value = ''
        basic.value = ''
        housing.value = ''
        transport.value = ''
        others.value = ''
        cra.value = 0
        pensions.value = 0
        nhf.value = 0
        total_relief.value = 0
        taxable_pay.value = 0
        payee.value = 0
        net_pay.value = 0
        employer_pensions.value = 0
        employee_cost.value = 0
    }

    watch(monthly_pay, () => {
        if (monthly_pay.value != '') {
            if (monthly_pay.value.toString().indexOf('.') == -1) {
                monthly_pay.value = parseFloat(monthly_pay.value)
            } else if (monthly_pay.value.toString().indexOf('.') != -1 && monthly_pay.value.toString().length - 1 != monthly_pay.value.toString().indexOf('.')) {
                monthly_pay.value = parseFloat(monthly_pay.value)
            }
        }
        if (isNaN(monthly_pay.value)) {
            monthly_pay.value = ''
        }
    })
    watch(basic, () => {
        if (basic.value != '') {
            if (basic.value.toString().indexOf('.') == -1) {
                basic.value = parseFloat(basic.value)
            } else if (basic.value.toString().indexOf('.') != -1 && basic.value.toString().length - 1 != basic.value.toString().indexOf('.')) {
                basic.value = parseFloat(basic.value)
            }
        }
        if (isNaN(basic.value)) {
            basic.value = ''
        }
    })
    watch(housing, () => {
        if (housing.value != '') {
            if (housing.value.toString().indexOf('.') == -1) {
                housing.value = parseFloat(housing.value)
            } else if (housing.value.toString().indexOf('.') != -1 && housing.value.toString().length - 1 != housing.value.toString().indexOf('.')) {
                housing.value = parseFloat(housing.value)
            }
        }
        if (isNaN(housing.value)) {
            housing.value = ''
        }
    })
    watch(transport, () => {
        if (transport.value != '') {
            if (transport.value.toString().indexOf('.') == -1) {
                transport.value = parseFloat(transport.value)
            } else if (transport.value.toString().indexOf('.') != -1 && transport.value.toString().length - 1 != transport.value.toString().indexOf('.')) {
                transport.value = parseFloat(transport.value)
            }
        }
        if (isNaN(transport.value)) {
            transport.value = ''
        }
    })
    watch(others, () => {
        if (others.value != '') {
            if (others.value.toString().indexOf('.') == -1) {
                others.value = parseFloat(others.value)
            } else if (others.value.toString().indexOf('.') != -1 && others.value.toString().length - 1 != others.value.toString().indexOf('.')) {
                others.value = parseFloat(others.value)
            }
        }
        if (isNaN(others.value)) {
            others.value = ''
        }
    })

    return {
        monthly_pay,
        deduct_pension,
        deduct_nhf,
        basic,
        housing,
        transport,
        others,
        total,
        cra,
        pensions,
        nhf,
        total_relief,
        taxable_pay,
        payee,
        net_pay,
        employer_pensions,
        employee_cost,
        preventDefault,
        submit,
        clear
    }
}