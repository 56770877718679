import { ref, watch } from "vue"
import Swal from "sweetalert2"

export default () => {
    const monthly_pay = ref('') as any
    const pension = ref('') as any
    const pension_value = ref(0)
    const consolidated_relief_allowance = ref(0)
    const total_relief = ref(0)
    const taxable_pay = ref(0)
    const paye = ref(0)
    const net_pay = ref(0)
    const employer_pension = ref(0)
    const employee_cost_to_company = ref(0)

    watch(monthly_pay, () => {
        if (monthly_pay.value != '') {
            monthly_pay.value = parseFloat(monthly_pay.value)
        }
        if (isNaN(monthly_pay.value)) {
            monthly_pay.value = ''
        }
    })

    const validator = () => {
        if (monthly_pay.value !== '') {
            if (pension.value !== '') {
                return true
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Select an option in `Deduct Pension`',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                })
                return false
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Fill in the monthly pay.',
                icon: 'error',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true
            })
            return false
        }
    }

    const submitForm = () => {
        if (validator()) {
            calculateNetPay()
            calculateEmployeeCostToCompany()
        }
    }

    const clearForm = () => {
        monthly_pay.value = ''
        pension.value = ''
        pension_value.value = 0
        consolidated_relief_allowance.value = 0
        total_relief.value = 0
        taxable_pay.value = 0
        paye.value = 0
        net_pay.value = 0
        employer_pension.value = 0
        employee_cost_to_company.value = 0
    }

    const calculateConsolidatedReliefAllowance: any = () => {
        const diff_value = monthly_pay.value - calculatePension()
        const a = (0.01 * diff_value * 12)
        const b = (0.2 * diff_value * 12)
        if (a > 200000) {
            return consolidated_relief_allowance.value = (a + b) / 12
        } else {
            return consolidated_relief_allowance.value = (200000 + b) / 12
        }
    }

    const calculatePension: any = () => {
        if (pension.value === 'yes') {
            return pension_value.value = 0.08 * monthly_pay.value
        } else if (pension.value === 'no') {
            return pension_value.value = 0
        }
    }

    const calculateTotalRelief = () => {
        return total_relief.value = calculateConsolidatedReliefAllowance() + calculatePension()
    }

    const calculateTaxablePay = () => {
        if (calculateTotalRelief() > monthly_pay.value) {
            return taxable_pay.value = monthly_pay.value
        } else {
            return taxable_pay.value = monthly_pay.value - calculateTotalRelief()
        }
    }

    // PAYE
    const a = 3200000 / 12
    const b = 560000 / 12
    const calculateC = () => {
        let total_value = 0
        if (calculateTaxablePay() > a) {
            total_value = (calculateTaxablePay() * 0.24 + b)
            return paye.value = total_value
        }
    }

    const d = 1600000 / 12
    const e = 224000 / 12
    const calculateF = () => {
        if (calculateTaxablePay() <= a) {
            return paye.value = ((calculateTaxablePay() - d) * 0.21 + e)
        } else {
            return calculateC()
        }
    }

    const g = 1100000 / 12
    const h = 129000 / 12
    const calculateI = () => {
        if (calculateTaxablePay() <= d) {
            return paye.value = ((calculateTaxablePay() - g) * 0.19 + h)
        } else {
            return calculateF()
        }
    }

    const j = 600000 / 12
    const k = 54000 / 12
    const calculateL = () => {
        if (calculateTaxablePay() <= g) {
            return paye.value = ((calculateTaxablePay() - j) * 0.15 + k)
        } else {
            return calculateI()
        }
    }

    const m = 300000 / 12
    const n = 21000 / 12
    const calculateO = () => {
        if (calculateTaxablePay() <= j) {
            return paye.value = ((calculateTaxablePay() - m) * 0.11 + n)
        } else {
            return calculateL()
        }
    }

    const calculateP = () => {
        if (calculateTaxablePay() <= m) {
            return paye.value = calculateTaxablePay() * 0.07
        } else {
            return calculateO()
        }
    }

    const calculateQ = () => {
        if (calculateTotalRelief() > monthly_pay) {
            return paye.value = 0.01 * monthly_pay
        } else {
            return calculateP()
        }
    }

    const calculatePaye: any = () => {
        if (monthly_pay <= 30000) {
            return paye.value = 0
        } else {
            return calculateQ()
        }
    }

    const calculateNetPay = () => {
        net_pay.value = monthly_pay.value - calculatePension() - calculatePaye()
    }

    const calculateEmployerPension = () => {
        employer_pension.value = calculatePension() / 8 * 10
        return employer_pension.value
    }

    const calculateEmployeeCostToCompany = () => {
        employee_cost_to_company.value = monthly_pay.value + calculateEmployerPension()
    }

    return {
        monthly_pay,
        pension,
        submitForm,
        clearForm,
        pension_value,
        consolidated_relief_allowance,
        total_relief,
        taxable_pay,
        paye,
        net_pay,
        employer_pension,
        employee_cost_to_company
    }
}