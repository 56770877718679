import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-pc-green-2 hover:bg-green-700 rounded-lg shadow-md shadow-slate-800 -mt-12" }
const _hoisted_2 = { class: "bg-slate-100 hover:bg-slate-300 rounded-lg shadow-md shadow-slate-800 -mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_calculator = _resolveComponent("simple-calculator")!
  const _component_complex_calculator = _resolveComponent("complex-calculator")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["", { 'bg-pc-green': _ctx.calculator == 0, 'bg-slate-100': _ctx.calculator == 1 }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex justify-between items-center border-t px-56 mb-3", { 'bg-pc-green': _ctx.calculator == 0, 'bg-slate-100': _ctx.calculator == 1 }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "text-slate-50 font-bold text-4xl py-6 px-14",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchCalculator(0)))
        }, " Simple Calculator ")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "text-pc-green font-bold text-4xl py-6 px-14",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.switchCalculator(1)))
        }, " Detailed Calculator ")
      ])
    ], 2),
    (_ctx.calculator == 0)
      ? (_openBlock(), _createBlock(_component_simple_calculator, {
          key: 0,
          class: "animate__animated animate__slideInLeft"
        }))
      : _createCommentVNode("", true),
    (_ctx.calculator == 1)
      ? (_openBlock(), _createBlock(_component_complex_calculator, {
          key: 1,
          class: "animate__animated animate__slideInRight"
        }))
      : _createCommentVNode("", true)
  ], 2))
}