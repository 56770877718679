export default (taxable_pay: any, total_relief: any, monthly_pay: any) => {
    console.log(taxable_pay, total_relief, monthly_pay)
    const a = 3200000 / 12
    const b = 560000 / 12

    const c = () => {
        if (taxable_pay > a) {
            return (taxable_pay - a) * 0.24 + b
        }
    }

    const d = 1600000 / 12
    const e = 224000 / 12
    const f = () => {
        if (taxable_pay <= a) {
            return (taxable_pay - d) * 0.21 + e
        } else {
            return c()
        }
    }

    const g = 1100000 / 12
    const h = 129000 / 12
    const i = () => {
        if (taxable_pay <= d) {
            return (taxable_pay - g) * 0.19 + h
        } else {
            return f()
        }
    }

    const j = 600000 / 12
    const k = 54000 / 12
    const l = () => {
        if (taxable_pay <= g) {
            return (taxable_pay - j) * 0.15 + k
        } else {
            return i()
        }
    }

    const m = 300000 / 12
    const n = 21000 / 12
    const o = () => {
        if (taxable_pay <= j) {
            return (taxable_pay - m) * 0.11 + n
        } else {
            return l()
        }
    }

    const p = () => {
        if (taxable_pay <= m) {
            return taxable_pay * 0.07
        } else {
            return o()
        }
    }

    const q = () => {
        if (total_relief > monthly_pay) {
            return 0.01 * monthly_pay
        } else {
            return p()
        }
    }

    const r = 30000
    const calcPaye = () => {
        if (monthly_pay <= r) {
            return 0
        } else {
            return q()
        }
    }

    return { calcPaye }
}