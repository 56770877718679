import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center lato" }
const _hoisted_2 = { class: "w-1/2" }
const _hoisted_3 = {
  key: 0,
  class: ""
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-3xl font-bold" }
const _hoisted_6 = { class: "text-blue-600 text-2xl cursor-pointer" }
const _hoisted_7 = { class: "w-1/2" }
const _hoisted_8 = {
  key: 0,
  class: ""
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "text-3xl font-bold" }
const _hoisted_11 = { class: "text-blue-600 text-2xl cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.position == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.image,
              class: ""
            }, null, 8, _hoisted_4),
            _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.link_text), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.position == 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("img", {
              src: _ctx.image,
              class: ""
            }, null, 8, _hoisted_9),
            _createElementVNode("h4", _hoisted_10, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.link_text), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}